

:root {
  --background-color: #60D658;
  --raw-seinna: #60D658;
  --sizzling-sunrise: hsl(51, 95%, 54%);
  --scarlet: hsl(13, 96%, 47%);
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);

  /**
   * typography
   */

  --ff-saira-stencil-one: "Saira Stencil One", sans-serif; 
  --ff-poppins: 'Poppins', sans-serif;
  --ff-roboto: 'Roboto', sans-serif;

  --fs-1: 2rem;
  --fs-2: calc(1.813rem + 1vw);
  --fs-3: calc(1.313rem + 1vw);
  --fs-4: 1.4rem;
  --fs-5: 1rem;
  --fs-6: 0.813rem;
  --fs-7: 0.75rem;

  --fw-400: 400;
  --fw-700: 700;

  /**
   * transition
   */

  --transition-1: 0.25s ease-in-out;

  /**
   * spacing
   */

  --section-padding: 80px;

  /**
   * radius
   */

  --radius-4: 4px;
  --radius-12: 12px;

}





/*-----------------------------------*\
 * #THEME COLORS
\*-----------------------------------*/

body.dark_theme {

  --bg-primary: hsl(0, 0%, 12%);
  --bg-secondary: hsl(0, 0%, 19%);
  --color-primary: hsl(0, 0%, 100%);
  --color-secondary: hsl(0, 0%, 62%);
  --card-shadow: hsla(0, 0%, 0%, 0.4);
  --input-bg: hsl(0, 0%, 16%);

  --shadow-1: 10px 10px 40px var(--card-shadow);

}

body.light_theme {

  --bg-primary: hsl(0, 0%, 90%);
  --bg-secondary: hsl(0, 0%, 100%);
  --color-primary: hsl(0, 0%, 12%);
  --color-secondary: hsl(0, 0%, 37%);
  --card-shadow: hsla(0, 0%, 0%, 0.1);
  --input-bg: hsl(0, 0%, 93%);

  --shadow-1: 10px 10px 40px var(--card-shadow);

}





/*-----------------------------------*\
 * #RESET
\*-----------------------------------*/

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li { list-style: none; }

a { text-decoration: none; }

a,
img,
time,
span,
input,
label,
select,
button,
textarea,
ion-icon { display: block; }

input,
button,
select,
textarea {
  background: none;
  border: none;
  font: inherit;
}

button,
select { cursor: pointer; }

input,
textarea { width: 100%; }

ion-icon { pointer-events: none; }

:is(a, button, select) {
  outline-color: var(--scarlet);
  outline-offset: 3px;
}

::selection {
  background: var(--color-primary);
  color: var(--bg-primary);
}

html {
  font-family: var(--ff-roboto);
  scroll-behavior: smooth;
}

body {
  background: var(--bg-primary);
  transition: var(--transition-1);
}

body.active { overflow: hidden; }





/*-----------------------------------*\
 * #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 10px; }

.h1 {
  font-size: var(--fs-1);
  line-height: 1.2;
  font-weight: 400;
}

.h2,
.h3,
.h4 {
  color: var(--color-primary);
  font-family: var(--ff-poppins);
  line-height: 1.2;
}

.h2 { font-size: var(--fs-2); }

.h3 { font-size: var(--fs-3); }

.h4 { font-size: var(--fs-5); }

.w-100 { width: 100%; }

.btn {
  max-width: max-content;
  color: var(--color-primary);
  font-weight: var(--fw-700);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px 25px;
  border: 1px solid transparent;
  border-radius: var(--radius-4);
  transition: var(--transition-1);
}

.btn-primary { border-color: var(--color-primary); }

.btn-primary:is(:hover, :focus) {
  background: var(--color-primary);
  color: var(--bg-primary);
}

.btn-secondary {
  background: var(--background-color);
  border-color: var(--background-color);
  color: var(--black);
}

.btn-secondary:is(:hover, :focus) { --raw-seinna: hsl(24, 74%, 64%); }

.section-subtitle {
  position: relative;
  color: var(--color-secondary);
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.section-subtitle::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 60px;
  height: 2px;
  background: var(--color-secondary);
}

.section-title {
  max-width: 350px;
  margin-bottom: 30px;
}

.section-text {
  color: var(--color-secondary);
  line-height: 1.8;
  margin-bottom: 30px;
}

.tooltip {
  position: absolute;
  top: -40px;
  background: var(--raw-seinna);
  min-width: max-content;
  color: var(--white);
  font-size: 15px;
  font-weight: var(--fw-700);
  padding: 5px 10px;
  border-radius: var(--radius-4);
  box-shadow: var(--shadow-1);
  transform: translateY(-10px);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition-1);
  z-index: 1;
}





/*-----------------------------------*\
 * #HEADER
\*-----------------------------------*/

.header {
  padding-block: 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: var(--transition-1);
  z-index: 4;
}

.header.active {
  padding-block: 12px;
  background: var(--bg-secondary);
  box-shadow: var(--shadow-1);
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.logo {
  position: relative;
  min-width: 77px;
  z-index: 2;
}

.logo a {
  color: var(--color-primary);
  font-family: var(--ff-saira-stencil-one);
}

.logo span {
  display: inline-block;
  color: var(--raw-seinna);
  margin-left: 2px;
}

.navbar-actions {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
}

.navbar-actions select {
  color: var(--color-primary);
  width: 45px;
}

.navbar-actions option {
  background: var(--bg-primary);
  color: var(--color-primary);
}

.theme-btn {
  padding: 4px;
  width: 48px;
  background: var(--bg-secondary);
  border-radius: 100px;
  transition: var(--transition-1);
}

.header.active .theme-btn { background: var(--bg-primary); }

.theme-btn .icon {
  position: relative;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  box-shadow: inset 9px -6px var(--color-primary);
  transition: var(--transition-1);
}

.theme-btn.active .icon {
  left: 20px;
  box-shadow: inset 20px -20px var(--sizzling-sunrise);
}

.nav-toggle-btn {
  position: relative;
  transform: rotate(-55deg);
  transition: var(--transition-1);
  z-index: 2;
}

.nav-toggle-btn.active { transform: rotate(-45deg); }

.nav-toggle-btn span {
  width: 20px;
  height: 2px;
  background: var(--color-primary);
  margin: 5px;
  transition: var(--transition-1);
}

.nav-toggle-btn :is(.one, .three) { width: 10px; }

.nav-toggle-btn .one {
  margin-left: auto;
  transform-origin: left;
}

.nav-toggle-btn .three { transform-origin: right; }

.nav-toggle-btn.active .one { transform: rotate(90deg) translateX(-3px); }

.nav-toggle-btn.active .three { transform: rotate(90deg) translateX(3px); }

.navbar {
  position: fixed;
  background: var(--bg-secondary);
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  transition: 0.75s cubic-bezier(0.71, 0.01, 0.24, 0.99);
  transition-delay: 0.5s;
  z-index: 1;
}

.navbar.active {
  top: 0;
  visibility: visible;
  transition-delay: 0s;
}

.navbar-list > li {
  margin-block: 20px;
  padding-inline: 50px;
  overflow: hidden;
}

.navbar-link {
  position: relative;
  width: max-content;
  margin-inline: auto;
  color: var(--color-primary);
  font-family: var(--ff-poppins);
  font-size: var(--fs-1);
  padding: 10px;
  transform: translateY(50px);
  transition: 0.75s cubic-bezier(0.68, -0.55, 0.27, 2);
}

.navbar.active .navbar-link {
  transform: translateY(0);
  transition-delay: 0.5s;
}

.navbar-link::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 5px;
  width: 0;
  background: var(--raw-seinna);
  transition: var(--transition-1);
}

.navbar-link:is(:hover, :focus)::before { width: 100%; }





/*-----------------------------------*\
 * #HERO
\*-----------------------------------*/

main { overflow-x: hidden; }

.hero {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 115px;
  margin-bottom: var(--section-padding);
}

.hero-banner {
  max-width: 500px;
  width: 125%;
  margin-bottom: 30px;
}

:is(.hero-social-list, .scroll-down) { display: none; }

.hero-content { max-width: 450px; }

.hero-title {
  text-align: center;
  margin-bottom: 30px;
}

.hero .btn-primary { margin-inline: auto; }





/*-----------------------------------*\
 * #STATS
\*-----------------------------------*/

.stats { padding-block: var(--section-padding); }

.stats-list {
  display: grid;
  gap: 30px;
}

.stats-card {
  background: #458D40;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 25px;
  border-radius: var(--radius-12);
  box-shadow: var(--shadow-1);
  transition: var(--transition-1);
}

.stats-card:is(:hover, :focus) { transform: translateY(-5px); }

.stats-card .card-icon {
  background: #458D40;
  width: 60px;
  height: 60px;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.stats-card .card-icon img {
  width: 70%;
  filter: invert(1);
}

.stats-card .card-title {
  width: calc(100% - 95px);
  color: #FFFFFF;
  text-align: center;
}

.stats-card .card-title strong {
  display: block;
  color: var(--color-primary);
  font-size: initial;
  line-height: 1.3;
  margin-top: 5px;
}

.stats-card > ion-icon { color: var(--color-secondary); }





/*-----------------------------------*\
 * #ABOUT
\*-----------------------------------*/

.about { padding-block: var(--section-padding); }

.about-banner {
  max-width: 400px;
  margin-inline: auto;
  margin-bottom: 80px;
}

.about .btn {
  max-width: unset;
  width: 100%;
}

.about .btn-secondary { margin-bottom: 10px; }





/*-----------------------------------*\
 * #SKILLS
\*-----------------------------------*/

.skills { padding-block: var(--section-padding); }

.skills-content { margin-bottom: 50px; }

.skills-toggle {
  background: var(--bg-secondary);
  position: relative;
  width: max-content;
  margin-inline: auto;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 100px;
  box-shadow: var(--shadow-1);
  z-index: 1;
}

.skills-toggle::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  bottom: 5px;
  width: 93px;
  background: var(--raw-seinna);
  border-radius: 100px;
  transition: var(--transition-1);
  z-index: -1;
}

.skills-toggle.active::before {
  left: 98px;
  width: 94px;
}

.toggle-btn {
  color: var(--color-primary);
  font-family: var(--ff-poppins);
  padding: 5px 25px;
  transition: var(--transition-1);
}

.toggle-btn.active { color: var(--white); }

.skills-box.active .skills-list,
.skills-box .tools-list { display: none; }

.skills-box .skills-list,
.skills-box.active .tools-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {

  0% { opacity: 0; }
  100% { opacity: 1; }

}

.skill-card {
  position: relative;
  background: var(--bg-secondary);
  width: 80px;
  height: 80px;
  display: grid;
  place-items: center;
  border-radius: var(--radius-12);
  box-shadow: var(--shadow-1);
  cursor: help;
}

.skill-card:hover .tooltip {
  transform: translateY(0);
  opacity: 1;
}





/*-----------------------------------*\
 * #PROJECT
\*-----------------------------------*/

.project { padding-block: var(--section-padding); }

.project-list > li { margin-bottom: 25px; }

.project-card {
  position: relative;
  overflow: hidden;
  border-radius: var(--radius-12);
  box-shadow: 2px 4px 6px hsla(0, 0%, 0%, 0.2);
}

.project-card::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  transition: var(--transition-1);
}

.project-card:is(:hover, :focus)::after {
  background: hsla(0, 0%, 100%, 0.1);
  backdrop-filter: blur(0.5px);
}

.project-card .card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
}

.project-card .card-title { margin-bottom: 10px; }

.project-card :is(.card-title, .publish-date) {
  background: var(--bg-primary);
  padding: 6px 12px;
  width: max-content;
  box-shadow: var(--shadow-1);
  transform: translateY(20px);
  opacity: 0;
  transition: var(--transition-1);
}

.project-card .publish-date {
  font-size: var(--fs-6);
  color: var(--color-primary);
  transition-delay: 0s;
}

.project-card:is(:hover, :focus) :is(.card-title, .publish-date) {
  transform: translateY(0);
  opacity: 1;
}

.project-card:is(:hover, :focus) .publish-date { transition-delay: 0.1s; }

.load-more {
  font-size: var(--fs-4);
  color: var(--raw-seinna);
  text-decoration: underline;
  margin-inline: auto;
}





/*-----------------------------------*\
 * #CONTACT
\*-----------------------------------*/

.contact { padding-block: var(--section-padding); }

.contact-content { margin-bottom: 50px; }

.contact-list-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 20px;
}

.contact-item-icon {
  font-size: 25px;
  color: var(--color-primary);
}

.contact-item-icon ion-icon { --ionicon-stroke-width: 40px; }

.contact-item-title { margin-bottom: 10px; }

.contact-list-item .contact-info {
  color: var(--color-secondary);
  font-style: normal;
  line-height: 1.6;
  transition: var(--transition-1);
}

.contact-info:not(address):is(:hover, :focus) { color: var(--color-primary); }

.contac-social-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding-inline: 40px;
}

.contact-social-link {
  position: relative;
  background: var(--color-primary);
  color: var(--bg-primary);
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transition: var(--transition-1);
}

.contact-social-link:is(:hover, :focus) {
  background: var(--raw-seinna);
  color: var(--white);
}

.contact-social-link:is(:hover, :focus) .tooltip {
  transform: translateY(0);
  opacity: 1;
}

.contact-form {
  background: var(--bg-secondary);
  padding: 20px;
  border-radius: var(--radius-12);
  box-shadow: var(--shadow-1);
}

.form-wrapper { margin-bottom: 25px; }

.form-label {
  color: var(--color-primary);
  font-size: var(--fs-7);
  font-family: var(--ff-poppins);
  margin-bottom: 8px;
  margin-left: 5px;
}

.input-wrapper { position: relative; }

.input-field {
  background: var(--input-bg);
  color: var(--raw-seinna);
  padding: 10px;
  padding-left: 40px;
  border-radius: var(--radius-12);
  transition: var(--transition-1);
}

.input-wrapper ion-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--color-secondary);
  font-size: 20px;
  transition: var(--transition-1);
}

textarea.input-field {
  min-height: 60px;
  height: 120px;
  max-height: 200px;
  resize: vertical;
}

.input-field:focus { outline: 1px solid; }

.input-field:focus + ion-icon { color: var(--raw-seinna); }

.contact .btn-primary {
  max-width: unset;
  width: 100%;
  background: var(--color-primary);
  color: var(--bg-primary);
}





/*-----------------------------------*\
 * #FOOTER
\*-----------------------------------*/

.footer {
  background: var(--bg-secondary);
  padding-block: 20px;
  text-align: center;
  box-shadow: var(--shadow-1);
}

.footer .logo { margin-bottom: 15px; }

.copyright {
  color: var(--color-secondary);
  line-height: 1.6;
}

.copyright a {
  display: inline-block;
  color: var(--raw-seinna);
}





/*-----------------------------------*\
 * #GO TO TOP
\*-----------------------------------*/

.go-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 45px;
  height: 45px;
  display: grid;
  place-items: center;
  font-size: 20px;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: var(--transition-1);
  z-index: 2;
}

.go-top.active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
}





/*-----------------------------------*\
 * #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for larger than 550px screen
 */

@media (min-width: 550px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 550px;
    margin-inline: auto;
  }

  .section-title { max-width: 380px; }



  /**
   * PROJECT
   */

  .project-list {
    column-count: 2;
    column-gap: 25px;
  }

  .project-list > li:first-child { column-span: all; }

}





/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {

  /**
   * REUSED STYLE
   */

  .container { max-width: 720px; }

  .section-title { max-width: 430px; }



  /**
   * STATS
   */

  .stats-list { grid-template-columns: 1fr 1fr; }



  /**
   * ABOUT
   */

  .btn-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  .about .btn { max-width: max-content; }

  .about .btn-secondary { margin-bottom: 0; }



  /**
   * CONTACT
   */

  .contact-form {
    max-width: 450px;
    margin-inline: auto;
  }

}





/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-2: 3rem;

    /**
     * spacing
     */

    --section-padding: 100px;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 980px; }

  .section-content {
    position: relative;
    padding-left: 40px;
  }

  .section-subtitle {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(0.75turn) translateX(-100%);
    transform-origin: left top;
    margin-bottom: 0;
  }

  .section-subtitle::after {
    top: 8px;
    left: auto;
    right: calc(100% + 20px);
  }



  /**
   * HEADER
   */

  .header { padding-block: 30px; }

  .header.active { padding-block: 15px; }

  .navbar-actions {
    order: 1;
    margin-left: 0;
  }

  .nav-toggle-btn { display: none; }

  .navbar { all: unset; }

  .navbar-link {
    transform: translateY(0);
    font-size: unset;
    padding-inline: 5px;
  }

  .navbar-list > li {
    margin: 0;
    padding: 0;
    overflow: visible;
  }

  .navbar-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .navbar-link::before { height: 2px; }



  /**
   * HERO
   */

  .hero {
    position: relative;
    padding-top: 0;
    height: 100vh;
  }

  .hero-banner {
    max-width: unset;
    height: 100%;
    margin-bottom: 0;
  }

  .hero img {
    height: 100%;
    width: auto;
    margin-inline: auto;
  }

  .hero-content {
    --color-primary: var(--white);

    position: absolute;
    top: 50%;
    filter: drop-shadow(2px 4px 10px var(--black));
  }

  .hero .btn-primary:is(:hover, :focus) { color: hsl(0, 0%, 12%); }

  .hero-social-list {
    display: block;
    position: absolute;
    bottom: 92px;
    left: -30px;
  }

  .hero-social-list::after {
    content: "";
    position: absolute;
    bottom: -40px;
    left: 13px;
    width: 2px;
    height: 30px;
    background: var(--color-secondary);
  }

  .hero-social-link {
    position: relative;
    color: var(--color-secondary);
    margin-block: 5px;
    padding: 5px;
    font-size: 18px;
    transition: var(--transition-1);
  }

  .hero-social-link:is(:hover, :focus) { color: var(--color-primary); }

  .hero-social-link .tooltip {
    --trans-x: 10px;

    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%) translateX(var(--trans-x));
  }

  .hero-social-link:is(:hover, :focus) .tooltip {
    --trans-x: 0;
    opacity: 1;
  }

  .scroll-down {
    display: block;
    position: absolute;
    bottom: 80px;
    right: -30px;
    color: var(--color-secondary);
    font-size: 1.125rem;
    text-transform: uppercase;
    transform: rotate(90deg);
    transform-origin: top right;
  }

  .scroll-down::after {
    content: "";
    position: absolute;
    top: 8px;
    right: -50px;
    width: 30px;
    height: 2px;
    background: var(--color-secondary);
  }



  /**
   * STATS
   */

  .stats-list { grid-template-columns: repeat(3, 1fr); }

  .stats-card { height: 100%; }




  /**
   * ABOUT
   */

  .about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
  }

  .about-banner { margin-bottom: 0; }




  /**
   * SKILLS
   */

  .skills {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 50px;
  }

  .skills-content { margin-bottom: 0; }

  .skills-toggle { margin-inline: 0; }



  /**
   * PROJECT
   */

  .project-list { column-count: 3; }

  .project-list > li:first-child {
    column-span: unset;
    margin: 25px;
    margin-left: 0;
  }



  /**
   * CONTACT
   */

  .contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .contact-content { margin-bottom: 0; }

  .contact-form { width: 100%; }

  .contact-list { margin-left: -40px; }



  /**
   * FOOTER
   */

  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer .logo { margin-bottom: 0; }

}





/**
 * responsive for larger than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * REUSED STYLE
   */

  .container { max-width: 1150px; }

  .section-title { max-width: 460px; }




  /**
   * HERO
   */

  .hero-social-list { left: -80px; }

  .scroll-down { right: -80px; }




  /**
   * ABOUT
   */

  .about-banner { max-width: 450px; }

  .main{
   background-color: #f9f9f9;
  }
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .form-wrapper {
    margin-bottom: 20px;
  }

  .form-label {
    display: block;
    margin-bottom: 5px;
    font-size: 1.1em;
    color: #333;
    font-weight: 500;
  }

  .input-wrapper {
    position: relative;
  }

  .input-field {
    width: 100%;
    padding: 10px 40px 10px 15px;
    font-size: 1em;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
  }

  .input-field:focus {
    border-color: #0066cc;
    outline: none;
  }

  input::placeholder,
  textarea::placeholder {
    color: #888;
  }

  /* Icon styling */
  .input-wrapper ion-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    font-size: 1.2em;
  }

  .btn:hover {
    background-color: #458D40;
    color: #F1F1F1;
  }

.button-92 {
  --c: #fff;
  /* text color */
      text-align: center;

  background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
    #458D40;
  /* background color */
  color: #00333000;
  border: none;
  transform: perspective(500px) rotateY(calc(20deg*var(--_i,-1)));
  text-shadow: calc(var(--_i,-1)* 0.08em) -.01em 0   var(--c),
    calc(var(--_i,-1)*-0.08em)  .01em 2px #0004;
  outline-offset: .1em;
  transition: 0.3s;
}

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  cursor: pointer;
  padding: .1em .3em;
}
}