.header {
  padding-block: 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: var(--transition-1);
  z-index: 4;
}

.header.active {
  padding-block: 12px;
  background: var(--bg-secondary);
  box-shadow: var(--shadow-1);
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.logo {
  position: relative;
  min-width: 77px;
  z-index: 2;
}

.logo a {
  color: var(--color-primary);
  font-family: var(--ff-saira-stencil-one);
}

.logo span {
  display: inline-block;
  color: var(--raw-seinna);
  margin-left: 2px;
}

.navbar-actions {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
}

.navbar-actions select {
  color: var(--color-primary);
  width: 45px;
}

.navbar-actions option {
  background: var(--bg-primary);
  color: var(--color-primary);
}

.theme-btn {
  padding: 4px;
  width: 48px;
  background: var(--bg-secondary);
  border-radius: 100px;
  transition: var(--transition-1);
}

.header.active .theme-btn { background: var(--bg-primary); }

.theme-btn .icon {
  position: relative;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  box-shadow: inset 9px -6px var(--color-primary);
  transition: var(--transition-1);
}

.theme-btn.active .icon {
  left: 20px;
  box-shadow: inset 20px -20px var(--sizzling-sunrise);
}

.nav-toggle-btn {
  position: relative;
  transform: rotate(-55deg);
  transition: var(--transition-1);
  z-index: 2;
}

.nav-toggle-btn.active { transform: rotate(-45deg); }

.nav-toggle-btn span {
  width: 20px;
  height: 2px;
  background: var(--color-primary);
  margin: 5px;
  transition: var(--transition-1);
}

.nav-toggle-btn :is(.one, .three) { width: 10px; }

.nav-toggle-btn .one {
  margin-left: auto;
  transform-origin: left;
}

.nav-toggle-btn .three { transform-origin: right; }

.nav-toggle-btn.active .one { transform: rotate(90deg) translateX(-3px); }

.nav-toggle-btn.active .three { transform: rotate(90deg) translateX(3px); }

.navbar {
  position: fixed;
  background: var(--bg-secondary);
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  transition: 0.75s cubic-bezier(0.71, 0.01, 0.24, 0.99);
  transition-delay: 0.5s;
  z-index: 1;
}

.navbar.active {
  top: 0;
  visibility: visible;
  transition-delay: 0s;
}

.navbar-list > li {
  margin-block: 20px;
  padding-inline: 50px;
  overflow: hidden;
}

.navbar-link {
  position: relative;
  width: max-content;
  margin-inline: auto;
  color: var(--color-primary);
  font-family: var(--ff-poppins);
  font-size: var(--fs-1);
  padding: 10px;
  transform: translateY(50px);
  transition: 0.75s cubic-bezier(0.68, -0.55, 0.27, 2);
}

.navbar.active .navbar-link {
  transform: translateY(0);
  transition-delay: 0.5s;
}

.navbar-link::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 5px;
  width: 0;
  background: var(--raw-seinna);
  transition: var(--transition-1);
}

.navbar-link:is(:hover, :focus)::before { width: 100%; }